import React, { useState } from "react";
import {
  Typography,
  Button,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Container,
  StepButton,
  Card,
  Box,
} from "@mui/material";

import {
  useForm,
  FormProvider,
} from "react-hook-form";

import { useEffect } from "react";
import { useParams } from "react-router";

import Addpatient1 from "./Addpatient1_Doctor";
import Agreement from "./Agreement_Doctor";
import Consent from "./Consent_Doctor";
import Emergency from "./Emergency_Doctor"; 
import Financial from "./Financial_Doctor";
import HealthProvider from "./HealthProvider_Doctor";
import Hipaa from "./Hipaa_Doctor";
import Medications from "./Medications_Doctor";
import NonPrescription from "./NonPrescription_Doctor"; 
import PatientInsuranceInfo from "./PatientInsuranceInfo_Doctor";
import Prescription from "./Prescription_Doctor";
import { Link } from "react-router-dom";

import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";

function getSteps() {
  return ["Personal Info", "Insurance Info", "Emergency","PCP", "Medication", "Prescription", "OTC", "Hipaa", "CreditCard", "Financial", "Consent"];
}

function getStepContent(
  step,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSubmit
) {
  const val = {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  };
  switch (step) {
    case 0:
      return <Addpatient1 {...val}/>;
    case 1:
      return <PatientInsuranceInfo {...val} />;
    case 2:
      return <Emergency {...val} />;
    case 3:
      return <HealthProvider {...val} />;
    case 4:
      return <Medications {...val} />;
    case 5:
      return <Prescription {...val} />;
    case 6:
      return <NonPrescription {...val} />;
    case 7:
      return <Hipaa {...val} />;
    case 8:
      return <Agreement {...val} />;
    case 9:
      return <Financial {...val} />;
    case 10:
      return <Consent {...val} />;
    default:
      return "unknown step";
  }
}

const PatientsEditStepper_Doctor = () => {
  const { id } = useParams();
  console.log("hillo");
  const finalForm = useForm({
    defaultValues: initialValues,
  });

  // const forntImage = finalForm.watch("forntImage");
  // const backImage = finalForm.watch("backImage");

  const { themeStretch } = useSettings();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1 || step === 2 || step === 3 || step === 4;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   axios
  //     .post(
  //       "https://qakdci.seekinterns.com/2222/fetchspatient",JSON.stringify({ patient_id: id })
  //     )
  //     .then((response) => {
  //       console.log(id)
  //       console.log(response)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);


  const handleNext = async (event, id) => {
    console.log(id)
    if (activeStep == steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
    // const formData = new FormData();
    // formData.append("file", data.forntImage);
    // formData.append("file", data.backImage);
    // console.log(formData);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div>
      <Stepper nonLinear alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          // const labelProps = {};
          const stepProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepButton color="inherit" onClick={handleStep(index)}>
              {step}
              {/* <StepLabel {...labelProps}>{step}</StepLabel> */}
              </StepButton>
            </Step>
       
          );
        })}
      </Stepper>
      <br />
      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Forms Edited Successfully
        </Typography>
      ) : (
        <>
            <Formik
            onSubmit={handleNext}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Page title="Patient Edit">
                    <Container maxWidth={themeStretch ? false : "xl"}>
                      <Stack direction="column" spacing={0}>
                        <Stack direction="column" spacing={0}>
                          <Box m="20px">
                            <Card>
                            
                              <Box m="20px">

                                {/* dynamic form */}
                                {getStepContent(
                                  activeStep,
                                  values,
                                  errors,
                                  touched,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue
                                )}
                                <Box
                                  display="flex"
                                  justifyContent="end"
                                  m="20px"
                                >
                                  <Stack direction="row" spacing={2}>
                                    <Button
                                      variant="contained"
                                      disabled={activeStep === 0}
                                      onClick={handleBack}
                                      type="button"
                                      sx={{color:"white"}}
                                    >
                                      BACK
                                    </Button>
                                    
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      sx={{color:"white"}}
                                      type="submit"
                                    >
                                      {activeStep === steps.length - 1
                                        ? "FINISH"
                                        : "NEXT"}
                                    </Button>
                                  </Stack>
                                </Box>
                              </Box>
                            </Card>
                          </Box>
                        </Stack>
                      </Stack>
                    </Container>
                  </Page>
                </form>
              )}
            </Formik>
          
        </>
      )}
    </div>
  );
};

export default PatientsEditStepper_Doctor;

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().email("invalid email"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid"),
  dateofbirth: yup.string(),
  password: yup
    .string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  gender: yup.string(),
  maritalstatus: yup.string(),
  occupation: yup.string(),
  ssn: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  city: yup.string(),
  country: yup.string(),
  postal: yup.string(),
  relative_friend: yup.string()
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  dateofbirth: "",
  gender: "",
  maritalstatus: "",
  occupation: "",
  ssn: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  postal: "",
  diseaseName: [],
  selectDoctor: "",
  paymode: "",
  inuranceName: [],
  subscribersname: "",
  idateOfBirth: "",
  groupno: "",
  policyno: "",
  prelationship: "",
  ipa: "",
  ipaSpecification: "",
  forntImage: "",
  backImage: "",
  relative_friend:""
};
