import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// @mui
import { 
  Card,
  Stack,
  Button,
  Box,
  Container,
  Typography,
  Breadcrumbs, 
  TextField
 } from '@mui/material';
 import { Formik } from "formik";
 import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
// hooks
import useSettings from '../../hooks/useSettings';
import CustomSnackbar from "../../utils/CustomSnackbar";

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const HealthProvider_Doctor = () => {
  // const {id} = useParams()

  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
const [data, setData] = useState([])
const [primary_care_provider_name,setPCP] = useState("")
const [clientname_address1,setCaddress] = useState("")
const [client_phone_no1,setCPhone] = useState("")
const [pcp_faxnumber,setPCPfax] = useState("")
const [pcp_email,setPCPemail] = useState("")
const [client_referral_provider_name,setReff] = useState("")
const [medication_additionalnotes,setAdditional] = useState("")
const [preferred_pharmacy_name,setPrefPharmacy] = useState("")
const [pharmacy_phone, setPharmNo] = useState("")
const [pharmacy_address, setPAddress] = useState("")
const [client_phone_no2, setCPhone2] = useState("")
const [clientname_address2,setCaddress2] = useState("")
const location = useLocation();
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const { themeStretch } = useSettings();
  
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });

  useEffect(() => {
    axios
      .post(
        "https://qakdci.seekinterns.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        console.log(response)
        setData(response.data);
        setPCP(response.data[0].primary_care_provider_name)
        setCaddress(response.data[0].clientname_address1)
        setCPhone(response.data[0].client_phone_no1)
        setPCPfax(response.data[0].pcp_faxnumber)
        setPCPemail(response.data[0].pcp_email)
        setReff(response.data[0].client_referral_provider_name)
        setAdditional(response.data[0].medication_additionalnotes)
        setPrefPharmacy(response.data[0].preferred_pharmacy_name)
        setPharmNo(response.data[0].pharmacy_phone)
        setPAddress(response.data[0].pharmacy_address)
        setCPhone2(response.data[0].client_phone_no2)
        setCaddress2(response.data[0].clientname_address2)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.seekinterns.com/2222/health-provider-s4', {
        patient_id:location.state.id,
        primary_care_provider_name:primary_care_provider_name,
        clientname_address1:clientname_address1,
        client_phone_no1:client_phone_no1,
        pcp_faxnumber:pcp_faxnumber,
        pcp_email:pcp_email,
        client_referral_provider_name:client_referral_provider_name,
        medication_additionalnotes:medication_additionalnotes,
        preferred_pharmacy_name:preferred_pharmacy_name,
        pharmacy_phone:pharmacy_phone,
        pharmacy_address:pharmacy_address,
        client_phone_no2:client_phone_no2,
        clientname_address2:clientname_address2,
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}

const { open, severity, message, position } = openSnack;

  return (
    <>
        {CustomSnackbar(open, severity, message, position)}

    <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          <div role="presentation" >
    </div>
    <Box m="20px">
    <Card>
   
  
    <Box m="20px">
    <Helmet>
        <title> Add Patient_1 | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          4. Other Health Provider Information 
          </Typography>
        </Stack>
    </Box>
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
            <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                    <TextField
                fullWidth
                id='sub'
                type="text"
                label="Primary Care Provider"
                name="primary_care_provider_name"
                helperText="(PCP) Name"
                error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={primary_care_provider_name}
                onChange={(e) => setPCP(e.target.value)}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Clinic Name and Address"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
                value={clientname_address1}
                onChange={(e) => setCaddress(e.target.value)}
              />
               <TextField
                fullWidth
                id='sub'
                type="number"
                label="Phone Number"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={client_phone_no1}
                onChange={(e) => setCPhone(e.target.value)}
              />    
               {/* <TextField
                fullWidth
                id='sub'
                type="number"
                label="Fax Number"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={pcp_faxnumber}
                onChange={(e) => setPCPfax(e.target.value)}
              />   */}
               {/* <TextField
                fullWidth
                id='sub'
                type="text"
                label="Email Address"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={pcp_email}
                onChange={(e) => setPCPemail(e.target.value)}
              />    */}
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Referral Provider Name"
                // error={!!touched.lastName && !!errors.lastName}
                helperText="(if applicable)"
                sx={{ gridColumn: "span 1" }}
                value={client_referral_provider_name}
                onChange={(e) => setReff(e.target.value)}
              />   

<TextField
                fullWidth
                id='sub'
                type="text"
                label="Clinic Name and Address"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
                value={clientname_address2}
                onChange={(e) => setCaddress2(e.target.value)}
              />
               <TextField
                fullWidth
                id='sub'
                type="number"
                label="Phone Number"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={client_phone_no2}
                onChange={(e) => setCPhone2(e.target.value)}
              />    
               {/* <TextField
                fullWidth
                id='sub'
                type="number"
                label="Fax Number"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={pcp_faxnumber}
                onChange={(e) => setPCPfax(e.target.value)}
              />   */}
               {/* <TextField
                fullWidth
                id='sub'
                type="text"
                label="Email Address"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                // value={pcp_email}
                // onChange={(e) => setPCPemail(e.target.value)}
              />    */}

           
              <TextField
                id='sub'
                type="text"
                label="Preferred Pharmacy Name"
                error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={preferred_pharmacy_name}
                onChange={(e) => setPrefPharmacy(e.target.value)}
              /> 
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Address"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
                value={pharmacy_address}
                onChange={(e) => setPAddress(e.target.value)}
              />
              <TextField
                id='sub'
                type="number"
                label="Pharmacy Number"
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={pharmacy_phone}
                onChange={(e) => setPharmNo(e.target.value)}
              />
             
              <TextField
                fullWidth
                id='sub'
                type="textarea"
                label="Additional Information/Notes"
                // error={!!touched.lastName && !!errors.lastName}
                helperText="(for kaizen team)"
                sx={{ gridColumn: "span 4" }}
                value={medication_additionalnotes}
                onChange={(e) => setAdditional(e.target.value)}
              />   

              <Stack sx={{ gridColumn: "span 3" }}></Stack>

              <Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>
            </Box> 
          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>
     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}



export default HealthProvider_Doctor;
