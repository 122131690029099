import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import SignatureCanvas from "react-signature-canvas";
import axios from 'axios';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import CustomSnackbar from "../../utils/CustomSnackbar";
import { useLocation } from 'react-router-dom';
// @mui
import { 
  Card,
  Stack,
  Button,
  Box,
  Container,
  Typography,
  Breadcrumbs, 
 TextField, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio
 } from '@mui/material';
 import { Formik } from "formik";
 import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from 'react-router-dom';

// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------


const Financial_Doctor = () => {
  // const {id} = useParams()
  const signRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [canvasVisibility, setCanvasVisibility] = useState(false);
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
  const clearSignatureCanvas = useCallback(() => {
    signRef.current.clear();
    setCanvas(undefined);
    setCanvasVisibility(false);
  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = signRef.current.toDataURL();

    setCanvas(canvas);
    setCanvasVisibility(true);
  }, []);

  const [data, setData] = useState([])
  const [financial_printed_pame, setFPName]=useState("")
  const [financial_digitalsignature, setFDS] = useState("")
  const [financial_date, setFDate] = useState(new Date())
  const [method_of_payment, setMethod] = useState("")
  const [financial_relationship, setFRel] = useState("")
  const [value, setValue] = React.useState(null);
  const location = useLocation();

  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    axios
      .post(
        "https://qakdci.seekinterns.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        console.log(response)
        setData(response.data);
        setFPName(response.data[0].financial_printed_pame)
        setFDS(response.data[0].financial_digitalsignature)
        setFDate(response.data[0].financial_date)
        setMethod(response.data[0].method_of_payment)
        setFRel(response.data[0].financial_relationship)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.seekinterns.com/2222/financial-s10', {
        patient_id:location.state.id,
        financial_printed_pame:financial_printed_pame,
        financial_digitalsignature:financial_digitalsignature,
        financial_relationship:financial_relationship,
        method_of_payment:method_of_payment,
        financial_date:financial_date,
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}
  const { themeStretch } = useSettings();
  const { open, severity, message, position } = openSnack;

return (
    <>
        {CustomSnackbar(open, severity, message, position)}

    <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          {/* <Typography variant="h4" gutterBottom color="primary">
            Add Patient       
          </Typography> */}
          <div role="presentation" >
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Patients        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
         Add Patient
        </Typography>
      </Breadcrumbs> */}
    </div>
    <Box m="20px">
    <Card>
   
   
    <Box m="20px">
    <Helmet>
        <title> Add Patient_1 | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          10. Patient Financial Responsibilty Policy
          </Typography>
         
        </Stack>
        
            </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
            <form onSubmit={handleSubmit}>
              <Box
              
            >
                <p>
                We are committed to providing you with the best quality care and are pleased to discuss our professional fees with 
                  you at any time. Your clear understanding of our <strong>Financial Policy</strong> is important to our professional relationship.
                  Please do not hesitate to ask any questions about our fees, financial policy or your financial responsibility.
                </p> <br />
                <br />

                   <FormControl>
  <FormLabel id="demo-radio-buttons-group-label">Please check the box to select your method of payment:</FormLabel>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="female"
    name="radio-buttons-group"
    // defaultChecked={method_of_payment}
  > 
    <FormControlLabel value="0" control={<Radio onClick={(e)=> setMethod("0")} value="0" checked = {method_of_payment === "0"} />} label="I DO NOT have Medicare, Tricare or Commercial PPO Insurance Coverage, but I AGREE
to be financially responsible as a Fee-For-Service/Cash Pay Patient. I will pay for all
services rendered with my current credit card on file at time of service." /><br />
    <FormControlLabel value="1" control={<Radio onClick={(e)=> setMethod("1")} value="1" checked = {method_of_payment === "1"} />} label="I DO have Medicare or Tricare or a Kaizen Brain Center Contracted Commerical
Insurance. I understand that a claim will be filed and sent to Medicare or Tricare. I
understand that there may be services rendered at Kaizen Brain Center that are not covered
by my Insurance. I AGREE to be financially responsible for services not covered by Insurance
in addition to my designated Co-Pay." /><br />
    <FormControlLabel value="2" control={<Radio onClick={(e)=> setMethod("2")} value="2" checked = {method_of_payment === "2"}/>} label="I have insurance, but I DO NOT want claims to be filed with my insurance carrier. I
AGREE to be financially responsible as a Fee-For-Service/Cash Pay Patient. I do not want
Kaizen Brain Center to file a claim with my insurance company on my behalf." />
  </RadioGroup>
</FormControl>     
<br />
               <br />
              <p><strong>I AGREE</strong> to notify Kaizen Brain Center upon any changes to insurance and coverage by calling our Care Coordinator BEFORE changes go into effect.</p><br />
                <p><strong>REFERRALS</strong> - If your plan requires a referral from your primary care physician, it is the patient's responsibility to obtain the referral request BEFORE the scheduled appointment and have it with you at the time of your visit. If your plan requires a referral and you do not obtain one, you will be held responsible for the visit charges in full at the time of service.</p> <br />
                <p><strong>CO-PAYMENTS</strong> - By law we MUST collect your carrier designated co-pay. This payment is expected at the time of service. Please be prepared to pay the co-pay at each visit. We accept Cash, Visa, MasterCard, and American Express, Flexible Spending Account Visa, and Benefits Debit Cards only.</p><br />
                <p><strong>OUT OF NETWORK PLANS</strong> - You, the patient, will be responsible for any balance your plan indicates as due on the Explanation of Benefits (EOB) Form. All patients will be responsible for their co-insurance, co-payment, and deductible. If your Kaizen Brain Center Provider is not a "Preferred Provider" with your insurance plan, we will send a courtesy bill to that carrier on your behalf. However, should they not pay your claim within 45 days; you will be responsible for the full amount due. Should you receive any reimbursement payments directly from your insurance carrier, we ask that you please forward it to <strong>Kaizen Brain Center, 4180 La Jolla Village Dr., Suite 240, La Jolla, CA 92037</strong> because the payment is intended to be paid to our clinic, but most commonly gets mailed to the subscriber.</p><br />
                <p><em>Private Insurance Authorization for Assignment of Benefits/Information Release: I, the undersigned, authorize payment of medical benefits to Kaizen Brain Center for any services furnished. I understand that I am financially responsible for any amount not covered by my contract. I also authorize any holder of medical information about me to release to my insurance company (or their agent) information concerning health care, advice, treatment or supplies provided to me. This information will be used for the purpose of evaluating and administering claims of benefits.</em></p><br />
                <p><strong>SELF-PAY PATIENTS </strong>- Payment is expected at the time of service unless financial arrangements have been made prior to your visit. We accept Cash, Visa, MasterCard, American Express, Flexible Spending Account Visa, and Benefits Debit Card only.</p><br />
                <p><strong>MEDICARE</strong> - We will submit claims to Medicare. The patient will be responsible for the deductible and the 20% coinsurance, which can be billed to your secondary insurance if you have one. NOTE: Not all services are covered by Medicare.</p><br />
                <p><em>Medicare Lifetime Signature on File: I request that payment of authorized Medicare benefits be made on my behalf to Kaizen Brain Center for any services furnished to me. I authorize any holder of medical information about me to release to the CMS (and its agents) any information to determine these benefits payable for related services. This information will be used for the purpose of evaluating and administering claims of benefits.</em></p><br />
                <p><strong>APPOINTMENT CANCELLATION WITHOUT NOTICE FEES</strong> - If you were scheduled for an appointment and you do not provide Kaizen Brain Center with a <strong>24-hour Advance Cancellation Notice</strong>, a fee will be charged to your credit card on file. Fees are as follows:</p><br />
                <ul>
                  <strong>Cancellation Without Notice/No Show Fee :</strong>
                  <i><li>Consultations &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: $100.00</li></i>
                  <i><li>Dr. Jennifer Sumner Services : Price of Service</li></i>
                
                  {/* <li>Specialist Evaluation: $600</li>
                  <li>Follow-Up Visits: $100</li>
                  <li>TMS Mapping: $500</li>
                  <li>TMS Treatment: $150</li>
                  <li>CRT: 236</li>
                  <li>ABT: $500</li>
                  <li>Neuropsych Testing: $500</li>
                  <li>Psychotherapy: $150</li> */}
                </ul><br />
                <p>By not giving our clinic advance notice to cancel a confirmed appointment or not showing up to your scheduled appointment prevents us from scheduling another patient resulting in a longer wait time for patients who need to be seen.</p><br />
                <p><strong>PATIENT PHONE CONSULT FEE</strong>: All established patients are to communicate directly with our Care Coordinator who will then triage the calls, review emails received, and coordinate the requests for medication refills, changes in patient information, payment collection, insurance, and appointment scheduling/cancellation. All patient calls and emails will be responded to within 24-72 hours. Should the patient have any clinical questions or concerns that require the patient to speak with the doctor, our Care Coordinator will schedule a phone consult upon request after accepting financial responsibility for a <strong>$3 per minute fee</strong> due at time of service. If the patient agrees to this fee, the current credit card on file will be charged.</p><br />
                <p>Thank you for taking the time to review our policies. Please feel free to ask your Care Coordinator any questions or share with us special concerns. We are here to help you.</p><br />
                <p>By signing below, I agree to the terms of the "Patient Financial Responsibility Policy" document.</p>
            <br />
            <h4>Signature of patient or patient's legal representative (if applicable)</h4>    
              {/* <div className="sign" style={{width:"500px",height:"200px",border:"2px solid black"}} >
    <SignatureCanvas
        ref={signRef}
        canvasProps={{
          width: 500,
          height: 200
        }}
      />
    </div>   */}
              {/* <div>
    <Button type="submit" onClick={clearSignatureCanvas} color="secondary" variant="contained"style={{margin:"3vh"}} >
                Clear Signature
              </Button>
    <Button type="submit" onClick={handleGetCanvas} color="secondary" variant="contained" >
                Submit Signature
              </Button>          
  </div> */}
  {/* {canvasVisibility && 
  <div style={{width:"600px",height:"300px"}}>
  <img src={canvas} alt="signature" />
  </div>
  }  */}
  <Box   display="flex" style={{marginTop:"5vh"}}
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}  >
<TextField
                fullWidth
                id='sub'
                type="text"
                label="Printed Name"
                value={financial_printed_pame}
                onChange={(e) => setFPName(e.target.value)}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Signature"
                value={financial_digitalsignature}
                onChange={(e) => setFDS(e.target.value)}
                sx={{ gridColumn: "span 1" }}
              />
            <TextField
                fullWidth
                id='sub'
                type="text"
                value={financial_relationship}
                onChange={(e) => setFRel(e.target.value)}
                label="Relationship to patient (if applicable)"
                sx={{ gridColumn: "span 1" }}
              />
              
              
              </Box>
              <br />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        variant="filled"
        value={dayjs(financial_date)}
        sx={{ gridColumn: "span 1" }}
        onChange={(e) => {
          setFDate(e);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
<br />
<br />
<Stack sx={{ gridColumn: "span 3" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{width:"220px",  color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>
</Box>
          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>
     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}



export default Financial_Doctor;
