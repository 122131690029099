import React, { useState, useEffect, useRef, useMemo } from "react";
import front2 from "./f2.png";
import back from "./b6.png";
// import front2 from "./fronnt-new.png"
// import back from "./backhead-new.png"
import right from "./left3-new.png";
import left from "./right3-new.png";
import axios from "axios";
import { Form, FormGroup, FormCheck, Col, Row } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
// import "./App.css";
import {
  Box,
  Card,
  Button,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";

function HampView() {
  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  const imageBackRef = useRef(null);
  const canvasBackRef = useRef(null);

  const imageRightRef = useRef(null);
  const canvasRightRef = useRef(null);

  const imageLeftRef = useRef(null);
  const canvasLeftRef = useRef(null);
  const [id, setId] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch user data from the API
    fetch("https://test3.seekinterns.com/tmpgraphs")
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const handletext = (e) => {
    // setId(e.target.value);
    // console.log("ID is", id)

    axios
    .post(
      "https://qakdci.seekinterns.com/2222/calculateaverageintensity",
      JSON.stringify({ p_id: e.target.value })
    )
    .then((response) => {
      console.log("patient iD is",e.target.value);
      console.log("response is",response)
      setSelectedAreas(
        response.data.front_pain_points.split(",").map(Number)
      );
      setSelectedBackAreas(
        response.data.back_pain_points.split(",").map(Number)
      );
      setSelectedRightAreas(
        response.data.right_pain_points.split(",").map(Number)
      );
      setSelectedLeftAreas(
        response.data.left_pain_points.split(",").map(Number)
      );

      setSliderValues(JSON.parse(response.data.front));
      setSliderBackValues(JSON.parse(response.data.back));
      setSliderRightValues(JSON.parse(response.data.right));
      setSliderLeftValues(JSON.parse(response.data.left));
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  const areas = useMemo(
    () => [
      {
        id: 1,
        shape: "circle",
        coords: "146,117,15",
        title: "1. Right Eyebrow",
      },
      {
        id: 2,
        shape: "circle",
        coords: "184,117,15",
        title: "2. Left Eyebrow",
      },
      {
        id: 3,
        shape: "poly",
        coords: "137,76,195,77,166,95",
        title: "3. Forehead",
      },
      {
        id: 4,
        shape: "rect",
        coords: "94,58,130,83",
        title: "4. Right Forehead",
      },
      {
        id: 5,
        shape: "rect",
        coords: "203,55,238,79",
        title: "5. Left Forehead",
      },
      {
        id: 6,
        shape: "poly",
        coords: "140,45,136,36,144,28,161,25,178,26,193,32,197,40,189,48,168,50,152,50",
        title: "6. Top Head  ",
      },
    ],
    []
  );

  const areas_back = useMemo(
    () => [
      {
        id: 6,
        shape: "poly",
        coords:
          "208,55,212,49,199,41,175,38,145,41,130,50,142,60,173,63,198,59",
        title: "6. Top Back-Head",
      },
      {
        id: 8,
        shape: "rect",
        coords: "100,142,154,191",
        title: "8. Parietal Left",
      },
      {
        id: 7,
        shape: "rect",
        coords: "175,138,229,187",
        title: "7. Parietal Right",
      },
      {
        id: 9,
        shape: "rect",
        coords: "126,219,151,287",
        title: "9. Right Lower Neck",
      },
      {
        id: 10,
        shape: "rect",
        coords: "181,220,209,288",
        title: "10. Left Lower Neck",
      },
    ],
    []
  );

  // right,up,left,down
  const areas_right = useMemo(
    () => [
      {
        id: 6,
        shape: "poly",
        coords:
          "147,61,161,70,190,74,229,68,236,58,221,48,194,45,167,47,153,53",
        title: "6. Top Head",
      },
      {
        id: 7,
        shape: "rect",
        coords: "85,123,122,180",
        title: "7. Parietal Right",
      },
      {
        id: 11,
        shape: "circle",
        coords: "181,119,35",
        title: "11. Right Centric",
      },
      {
        id: 13,
        shape: "poly",
        coords: "212,154,212,173,236,173,237,183,260,165,236,146,236,155",
        title: "13. Right Eye",
      },
      {
        id: 15,
        shape: "rect",
        coords: "212,202,267,253",
        title: "15. Right Cheek",
      },
      {
        id: 17,
        shape: "poly",
        coords: "148,290,148,281,116,300,149,319,149,309,181,309,181,290",
        title: "17. Right Neck",
      },
    ],
    []
  );

  // right,up,left,down

  const areas_left = useMemo(
    () => [
      {
        id: 6,
        shape: "poly",
        coords:
          "135,66,145,74,170,78,193,77,215,73,224,64,202,52,177,51,144,55",
        title: "6. Top Head",
      },
      {
        id: 12,
        shape: "circle",
        coords: "180,128,33",
        title: "12. Left Centric",
      },
      {
        id: 8,
        shape: "rect",
        coords: "238,103,277,163",
        title: "8. Parietal Left",
      },
      {
        id: 14,
        shape: "poly",
        coords: "124,158,123,151,97,167,122,182,122,175,150,175,149,158",
        title: "14. Right Eye",
      },
      {
        id: 16,
        shape: "rect",
        coords: "90,211,148,262",
        title: "16. Right Cheek",
      },
      {
        id: 18,
        shape: "poly",
        coords: "195,282,221,282,220,274,245,290,221,305,220,297,195,297",
        title: "18. Left Neck",
      },
    ],
    []
  );

  // const initialSelectedAreas = useMemo(
  //   () => areas.map((area) => area.id),
  //   [areas]
  // );
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [sliderValues, setSliderValues] = useState({});
  console.log("Selected Areas:", selectedAreas);
  console.log("Slider Values:", sliderValues);
  useEffect(() => {
    const image = imageRef.current;
    const canvas = canvasRef.current;

    if (!image || !canvas) return;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    areas.forEach((area) => {
      if (selectedAreas.includes(area.id)) {
        const intensity = sliderValues[area.id] || 0;
        if (intensity === 0) {
          ctx.fillStyle = "green";
        } else {
          const gradient = ctx.createLinearGradient(0, 0, 1, 0);
          gradient.addColorStop(0, "green");
          gradient.addColorStop(0.5, "yellow");
          gradient.addColorStop(1, "red");
          ctx.fillStyle =
            intensity === 0 ? "green" : intensity <= 5 ? "yellow" : "red";
          ctx.globalAlpha = intensity / 10;
          ctx.shadowBlur = intensity;
          ctx.shadowColor = gradient;
        }
        ctx.beginPath();

        const coords = area.coords.split(",").map(Number);

        // Calculate scaling factors for X and Y
        const scaleX = image.width / image.naturalWidth;
        const scaleY = image.height / image.naturalHeight;

        if (area.shape === "poly") {
          ctx.moveTo(coords[0] * scaleX, coords[1] * scaleY);
          for (let i = 2; i < coords.length; i += 2) {
            ctx.lineTo(coords[i] * scaleX, coords[i + 1] * scaleY);
          }
        } else if (area.shape === "rect") {
          ctx.rect(
            coords[0] * scaleX,
            coords[1] * scaleY,
            (coords[2] - coords[0]) * scaleX,
            (coords[3] - coords[1]) * scaleY
          );
        } else if (area.shape === "circle") {
          const centerX = coords[0] * scaleX;
          const centerY = coords[1] * scaleY;
          const radius = coords[2] * scaleX;

          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        }

        ctx.closePath();
        ctx.fill();
        ctx.globalAlpha = 1;

        ctx.shadowBlur = 0;
        ctx.shadowColor = "transparent";
      }
    });
  }, [areas, selectedAreas, imageRef, canvasRef, sliderValues]);

  // const initialSelectedBackAreas = useMemo(
  //   () => areas_back.map((area) => area.id),
  //   [areas_back]
  // );
  const [selectedbackAreas, setSelectedBackAreas] = useState([]);
  const [sliderbackValues, setSliderBackValues] = useState({});
  console.log("Selected BAckAreas:",selectedbackAreas);
  console.log("Slider BackValues:",sliderbackValues);

  useEffect(() => {
    const imageBack = imageBackRef.current;
    const canvasBack = canvasBackRef.current;

    if (!imageBack || !canvasBack) return;

    // canvasRenderingContext2D
    const ctx = canvasBack.getContext("2d");
    ctx.clearRect(0, 0, canvasBack.width, canvasBack.height);

    areas_back.forEach((area) => {
      if (selectedbackAreas.includes(area.id)) {
        const intensity = sliderbackValues[area.id] || 0;
        if (intensity === 0) {
          ctx.fillStyle = "green";
        } else {
          const gradient = ctx.createLinearGradient(0, 0, 1, 0);
          gradient.addColorStop(0, "green");
          gradient.addColorStop(0.5, "yellow");
          gradient.addColorStop(1, "red");
          // console.log(sliderbackValues[area.id]);
          ctx.fillStyle =
            intensity === 0 ? "green" : intensity <= 5 ? "yellow" : "red";
          ctx.globalAlpha = intensity / 10;
          ctx.shadowBlur = intensity;
          ctx.shadowColor = gradient;
        }
        ctx.beginPath();

        const coords = area.coords.split(",").map(Number);

        const scaleX = imageBack.width / imageBack.naturalWidth;
        const scaleY = imageBack.height / imageBack.naturalHeight;

        if (area.shape === "poly") {
          ctx.moveTo(coords[0] * scaleX, coords[1] * scaleY);
          for (let i = 2; i < coords.length; i += 2) {
            ctx.lineTo(coords[i] * scaleX, coords[i + 1] * scaleY);
          }
        } else if (area.shape === "rect") {
          ctx.rect(
            coords[0] * scaleX,
            coords[1] * scaleY,
            (coords[2] - coords[0]) * scaleX,
            (coords[3] - coords[1]) * scaleY
          );
        } else if (area.shape === "circle") {
          const centerX = coords[0] * scaleX;
          const centerY = coords[1] * scaleY;
          const radius = coords[2] * scaleX;

          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        }

        ctx.closePath();
        ctx.fill();
        ctx.globalAlpha = 1;

        ctx.shadowBlur = 0;
        ctx.shadowColor = "transparent";
      }
    });
  }, [
    areas_back,
    selectedbackAreas,
    imageBackRef,
    canvasBackRef,
    sliderbackValues,
  ]);

  // const initialSelectedRightAreas = useMemo(
  //   () => areas_right.map((area) => area.id),
  //   [areas_right]
  // );
  const [selectedRightAreas, setSelectedRightAreas] = useState([]);
  const [sliderRightValues, setSliderRightValues] = useState({});
  console.log("Selected RightAreas:",selectedRightAreas);
  console.log("Slider RightValues:",sliderRightValues);

  useEffect(() => {
    const imageRight = imageRightRef.current;
    const canvasRight = canvasRightRef.current;

    if (!imageRight || !canvasRight) return;

    // canvasRenderingContext2D
    const ctx = canvasRight.getContext("2d");
    ctx.clearRect(0, 0, canvasRight.width, canvasRight.height);

    areas_right.forEach((area) => {
      if (selectedRightAreas.includes(area.id)) {
        const intensity = sliderRightValues[area.id] || 0;
        if (intensity === 0) {
          ctx.fillStyle = "green";
        } else {
          const gradient = ctx.createLinearGradient(0, 0, 1, 0);
          gradient.addColorStop(0, "green");
          gradient.addColorStop(0.5, "yellow");
          gradient.addColorStop(1, "red");
          // console.log(sliderRightValues[area.id]);
          ctx.fillStyle =
            intensity === 0 ? "green" : intensity <= 5 ? "yellow" : "red";
          ctx.globalAlpha = intensity / 10;

          ctx.shadowBlur = intensity;
          ctx.shadowColor = gradient;
        }
        ctx.beginPath();

        const coords = area.coords.split(",").map(Number);

        const scaleX = imageRight.width / imageRight.naturalWidth;
        const scaleY = imageRight.height / imageRight.naturalHeight;

        if (area.shape === "poly") {
          ctx.moveTo(coords[0] * scaleX, coords[1] * scaleY);
          for (let i = 2; i < coords.length; i += 2) {
            ctx.lineTo(coords[i] * scaleX, coords[i + 1] * scaleY);
          }
        } else if (area.shape === "rect") {
          ctx.rect(
            coords[0] * scaleX,
            coords[1] * scaleY,
            (coords[2] - coords[0]) * scaleX,
            (coords[3] - coords[1]) * scaleY
          );
        } else if (area.shape === "circle") {
          const centerX = coords[0] * scaleX;
          const centerY = coords[1] * scaleY;
          const radius = coords[2] * scaleX;

          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        }

        ctx.closePath();
        ctx.fill();
        ctx.globalAlpha = 1;

        ctx.shadowBlur = 0;
        ctx.shadowColor = "transparent";
      }
    });
  }, [
    areas_right,
    selectedRightAreas,
    imageRightRef,
    canvasRightRef,
    sliderRightValues,
  ]);

  // const initialSelectedLeftAreas = useMemo(
  //   () => areas_left.map((area) => area.id),
  //   [areas_left]
  // );
  const [selectedLeftAreas, setSelectedLeftAreas] = useState([]);
  const [sliderLeftValues, setSliderLeftValues] = useState({});
  console.log("Selected LeftAreas:",selectedLeftAreas);
  console.log("Slider LeftValues:",sliderLeftValues);

  useEffect(() => {
    const imageLeft = imageLeftRef.current;
    const canvasLeft = canvasLeftRef.current;

    if (!imageLeft || !canvasLeft) return;

    // canvasRenderingContext2D
    const ctx = canvasLeft.getContext("2d");
    ctx.clearRect(0, 0, canvasLeft.width, canvasLeft.height);

    areas_left.forEach((area) => {
      if (selectedLeftAreas.includes(area.id)) {
        const intensity = sliderLeftValues[area.id] || 0;
        if (intensity === 0) {
          ctx.fillStyle = "green";
        } else {
          const gradient = ctx.createLinearGradient(0, 0, 1, 0);
          gradient.addColorStop(0, "green");
          gradient.addColorStop(0.5, "yellow");
          gradient.addColorStop(1, "red");
          // console.log(sliderLeftValues[area.id]);
          ctx.fillStyle =
            intensity === 0 ? "green" : intensity <= 5 ? "yellow" : "red";
          ctx.globalAlpha = intensity / 10;

          ctx.shadowBlur = intensity;
          ctx.shadowColor = gradient;
        }
        ctx.beginPath();

        const coords = area.coords.split(",").map(Number);
        const scaleX = imageLeft.width / imageLeft.naturalWidth;
        const scaleY = imageLeft.height / imageLeft.naturalHeight;

        if (area.shape === "poly") {
          ctx.moveTo(coords[0] * scaleX, coords[1] * scaleY);
          for (let i = 2; i < coords.length; i += 2) {
            ctx.lineTo(coords[i] * scaleX, coords[i + 1] * scaleY);
          }
        } else if (area.shape === "rect") {
          ctx.rect(
            coords[0] * scaleX,
            coords[1] * scaleY,
            (coords[2] - coords[0]) * scaleX,
            (coords[3] - coords[1]) * scaleY
          );
        } else if (area.shape === "circle") {
          const centerX = coords[0] * scaleX;
          const centerY = coords[1] * scaleY;
          const radius = coords[2] * scaleX;

          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        }

        ctx.closePath();
        ctx.fill();
        ctx.globalAlpha = 1;

        ctx.shadowBlur = 0;
        ctx.shadowColor = "transparent";
      }
    });
  }, [
    areas_left,
    selectedLeftAreas,
    imageLeftRef,
    canvasLeftRef,
    sliderLeftValues,
  ]);

  const isNonMobile = useMediaQuery("(min-width:600px)");
  return (
    <>
      <title> TMP Questionnaires | Kaizen Digital </title>
      <center>
        <Typography color="primary" variant="h3" sx={{ mb: 5 }}>
          Headache Monitoring Programme{" "}
        </Typography>
      </center>
      <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, 1fr)">
        <select
          label="Patient Name"
          onChange={e => handletext(e)}
          style={{
            borderRadius: "5px",
            borderColor: "#CDCDCD",
            color: "#5A5A5A",
            gridColumn: isNonMobile ? "span 1" : "span 4",
          }}
        >
          {users.map((user) => (
            <option 
            key={user.id}
             value={user.id}
            >
              {user.pname}
            </option>
          ))}
        </select>
      </Box>

      <Box
        m="10px"
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        mt={5}
        border="3px solid"
        borderRadius="10px"
      >
        <Box  style={{ gridColumn: "span 4",display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }} m="30px" >
        <Typography color="primary" variant="h5" sx={{ mt: 0 }}>
          Intensity Bar
        </Typography>
        <Slider
        min={0}
        max={10}
        step={1}
        marks={marks}
        // value={sliderValues[area.id] || 0}
        // onChange={(value) => handleSliderChange(area.id, value)}
        trackStyle={{
          background: `linear-gradient(to right, green 0%, yellow 50%, red ${
                            sliderValues * 10
                          }%)`,
                        }}
                       
                        railStyle={{
                          background:
                          "linear-gradient(to right, green 0%,  yellow 50%, red 100%)",
                        }}
                        style={{
                          width: "50%",
                          margin: "23px",
                          pointerEvents: "none",
                        }}
                        />
                        </Box>
        <div style={{ position: "relative" }}>
          <img
            sx={{ width: "200px", height: "20%" }}
            ref={imageRef}
            src={front2}
            useMap="#image-map"
            alt="Map"
            onLoad={() => {
              const canvas = canvasRef.current;
              const image = imageRef.current;

              if (image && canvas) {
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;
              }
            }}
          />
          <canvas
            ref={canvasRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
            }}
          ></canvas>
          <map name="image-map">
            {areas.map((area) => (
              <area
                key={area.id}
                shape={area.shape}
                coords={area.coords}
                // onClick={() => handleAreaClick(area)}
                alt={area.title}
              />
            ))}
          </map>
        </div>
        {/* <div style={{ position: "relative" }}>
          <Form style={{ marginLeft: "20px" }}>
            <FormGroup>
              {areas.map((area) => (
                <Row
                  key={area.id}
                  className="mb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                ></Row>
              ))}
            </FormGroup>
          </Form>
        </div> */}

        <div style={{ position: "relative" }}>
          <img
            ref={imageBackRef}
            src={back}
            useMap="#image-map2"
            alt="Map"
            onLoad={() => {
              const canvasBack = canvasBackRef.current;
              const imageBack = imageBackRef.current;

              if (imageBack && canvasBack) {
                canvasBack.width = imageBack.naturalWidth;
                canvasBack.height = imageBack.naturalHeight;
              }
            }}
          />
          <canvas
            ref={canvasBackRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
            }}
          ></canvas>
          <map name="image-map2">
            {areas_back.map((area) => (
              <area
                key={area.id}
                shape={area.shape}
                coords={area.coords}
                // onClick={() => handleAreaBackClick(area)}
                alt={area.title}
              />
            ))}
          </map>
        </div>
        {/* <div style={{ position: "relative" }}>
          <Form style={{ marginLeft: "20px" }}>
            <FormGroup>
              {areas_back.map((area) => (
                <Row
                  key={area.id}
                  className="mb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                ></Row>
              ))}
            </FormGroup>
          </Form>
        </div> */}

        <div style={{ position: "relative" }}>
          <img
            ref={imageRightRef}
            src={right}
            useMap="#image-map3"
            alt="Map"
            onLoad={() => {
              const canvasRight = canvasRightRef.current;
              const imageRight = imageRightRef.current;

              if (imageRight && canvasRight) {
                canvasRight.width = imageRight.naturalWidth;
                canvasRight.height = imageRight.naturalHeight;
              }
            }}
          />
          <canvas
            ref={canvasRightRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
            }}
          ></canvas>
          <map name="image-map3">
            {areas_right.map((area) => (
              <area
                key={area.id}
                shape={area.shape}
                coords={area.coords}
                // onClick={() => handleAreaRightClick(area)}
                alt={area.title}
              />
            ))}
          </map>
        </div>
        {/* <div style={{ position: "relative" }}>
          <Form style={{ marginLeft: "20px" }}>
            <FormGroup>
              {areas_right.map((area) => (
                <Row
                  key={area.id}
                  className="mb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                ></Row>
              ))}
            </FormGroup>
          </Form>
        </div> */}

        <div style={{ position: "relative" }}>
          <img
            ref={imageLeftRef}
            src={left}
            useMap="#image-map4"
            alt="Map"
            onLoad={() => {
              const canvasLeft = canvasLeftRef.current;
              const imageLeft = imageLeftRef.current;

              if (imageLeft && canvasLeft) {
                canvasLeft.width = imageLeft.naturalWidth;
                canvasLeft.height = imageLeft.naturalHeight;
              }
            }}
          />
          <canvas
            ref={canvasLeftRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
            }}
          ></canvas>
          <map name="image-map4">
            {areas_left.map((area) => (
              <area
                key={area.id}
                shape={area.shape}
                coords={area.coords}
                // onClick={() => handleAreaLeftClick(area)}
                alt={area.title}
              />
            ))}
          </map>
        </div>
        {/* <div style={{ position: "relative" }}>
          <Form style={{ marginLeft: "20px" }}>
            <FormGroup>
              {areas_left.map((area) => (
                <Row
                  key={area.id}
                  className="mb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                ></Row>
              ))}
            </FormGroup>
          </Form>
        </div> */}
        {/* <Box style={{ gridColumn: "span 2", textAlign: 'center' }} border="2px solid" borderRadius="10px" m="30px" >
        <Typography color="primary" variant="h6" sx={{ mt: 0.5 }}>Most Frequent Areas:</Typography>
        <br/>
        5,2,7,9
        </Box>
        <Box style={{ gridColumn: "span 2", textAlign: 'center' }} border="2px solid" borderRadius="10px" m="30px">
        <Typography color="primary" variant="h6" sx={{ mt: 0.5 }}>Highest Intensity:</Typography>
        <br/>
        8
        </Box> */}
      </Box>
    </>
  );
}

export default HampView;