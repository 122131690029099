import React from 'react';
import { useIdle } from 'react-use';
import { useNavigate} from "react-router-dom";
import { Navigate } from "react-router-dom";
import CustomSnackbar from "../src/utils/CustomSnackbar";


const UserActivityDetector = () => {
  const isIdle = useIdle(300000);
  const navigate = useNavigate();

  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    severity: null,
    message: null,
  });

  React.useEffect(() => {
    if (isIdle) {
      localStorage.clear();
      // setTimeout(() => {
        navigate("/login");
      // }, 1000);

    setOpenSnack({
    open: true,
    severity: "info",
    message: "Due to 5 minutes of inactivity, you have been logged out. Please refresh this page and Sign In to continue.",
  width: "100%"
  });

    }
  }, [isIdle]);



  const { open, severity, message } = openSnack;

  return (
    <div>
      {CustomSnackbar(open, severity, message, ["bottom", "center"])}
    </div>
  );
};

export default UserActivityDetector;