import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import SignatureCanvas from "react-signature-canvas";
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
// @mui
import { 
  Card,
  Stack,
  Button,
  Box,
  Container,
  Typography,
  Breadcrumbs, TextField, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio
 } from '@mui/material';
 import { Formik } from "formik";
 import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useSettings from '../../hooks/useSettings';
import CustomSnackbar from "../../utils/CustomSnackbar";

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------


const Agreement_Doctor = () => {

  const signRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [canvasVisibility, setCanvasVisibility] = useState(false);
  // const {id} = useParams()
  const clearSignatureCanvas = useCallback(() => {
    signRef.current.clear();
    setCanvas(undefined);
    setCanvasVisibility(false);
  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = signRef.current.toDataURL();

    setCanvas(canvas);
    setCanvasVisibility(true);
  }, []);


  const [value, setValue] = React.useState(null);
  const [value1, setValue1] = React.useState(null);
  const [value2, setValue2] = React.useState(null);
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
  const [data, setData] = useState([])
  const [creditcard_first_name, setCCFName] = useState("")
  const [credit_card_date,setCCDate] = useState(new Date())
  const [name_on_creditcard,setCCName] = useState("")
  const [credit_card_number,setCCNum] = useState("")
  const [typeof_creditcard,setTypeCC] = useState("")
  const [expiration_date,setCCexpiration] = useState(new Date())
  const [security_code,setSecurity] = useState("")
  const [billing_address,setBAddress] = useState("")
  const [credit_city,setCity] = useState("")
  const [credit_state,setState] = useState("")
  const [credit_zip,setZip] = useState("")
  const [credit_signature,setSign] = useState("")
  const [credit_date, setCrDate] = useState(new Date())
  // const [credit_date, setCrDate] = useState("")
  const location = useLocation();

  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    axios
      .post(
        "https://qakdci.seekinterns.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        console.log(response)
        setData(response.data);
        setCCFName(response.data[0].creditcard_first_name)
        setCCDate(response.data[0].credit_card_date)
        setCCName(response.data[0].name_on_creditcard)
        setCCNum(response.data[0].credit_card_number)
        setTypeCC(response.data[0].typeof_creditcard)
        setCCexpiration(response.data[0].expiration_date)
        setSecurity(response.data[0].security_code)
        setBAddress(response.data[0].billing_address)
        setCity(response.data[0].credit_city)
        setState(response.data[0].credit_state)
        setZip(response.data[0].credit_zip)
        setSign(response.data[0].credit_signature)
        setCrDate(response.data[0].credit_date)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.seekinterns.com/2222/agreement-s9', {
        patient_id:location.state.id,
        creditcard_first_name:creditcard_first_name,
        credit_card_date:credit_card_date,
        name_on_creditcard:name_on_creditcard,
        credit_card_number:credit_card_number,
        typeof_creditcard:typeof_creditcard,
        expiration_date:expiration_date,
        security_code:security_code,
        billing_address:billing_address,
        credit_city:credit_city,
        credit_state:credit_state,
        credit_zip:credit_zip,
        credit_signature:credit_signature,
        credit_date:credit_date,
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}



  const { themeStretch } = useSettings();
  

  const { open, severity, message, position } = openSnack;

  return (
    <>
        {CustomSnackbar(open, severity, message, position)}

    <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          {/* <Typography variant="h4" gutterBottom color="primary">
            Add Patient       
          </Typography> */}
          <div role="presentation">
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Patients        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
         Add Patient
        </Typography>
      </Breadcrumbs> */}
    </div>
    <Box m="20px">
    <Card>
   

   
    <Box m="20px">
    <Helmet>
        <title> patients | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          9. Credit Card Policy and Agreement
          </Typography>
        </Stack>
        
        
            </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
            <form onSubmit={handleSubmit}>
              <Box>                        <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                    <TextField
                fullWidth
                id='sub'
                type="text"
                label="Full Name"
                value={creditcard_first_name}
                onChange={(e) => setCCFName(e.target.value)}
                name="lastName"
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        variant="filled"
        value={dayjs(credit_card_date)}
        sx={{ gridColumn: "span 2" }}
        onChange={(newValue2) => {
          setCCDate(newValue2);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
                
              <br />
            </Box> 
            <br />
            <p>
             At Kaizen Brain Center, we require keeping your credit or debit card on file as a convenient method of paying for services provided. Your credit card information is kept confidential and secure.
             </p>
             <br />
            <Box  display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }} >

<TextField
                fullWidth
                id='sub'
                type="text"
                label="Name on Credit Card"
                name="lastName"
                value={name_on_creditcard}
                onChange={(e) => setCCName(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
              fullWidth
                id='sub'
                type="number"
                label="Credit Card Number"
                name="lastName"
                value={credit_card_number}
                onChange={(e) => setCCNum(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
            </Box>
            <Box   display="flex" style={{marginTop:"5vh"}}
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}  >

            {/* <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Type of Credit Card</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Visa" control={<Radio />} label="Visa" />
        <FormControlLabel value="Mastercard" control={<Radio />} label="Mastercard" />
        <FormControlLabel value="American Express" control={<Radio />} label="American Express" />
        <FormControlLabel value="Discover" control={<Radio />} label="Discover" />
    
      </RadioGroup>
    </FormControl> */}
    <FormControl
          sx={{
            gridColumn: "span 4",
          }}
        >
          <FormLabel id="demo-row-radio-buttons-group-label">
          Type of Credit Card
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue=""
          >
            {["Visa", "Mastercard", "AmericanExpress", "Discover"].map((name) => (
                  <FormControlLabel
                    control={
                      <Radio
                        id="typeof_creditcard"
                        type="radio"
                        value={name}
                        onChange={(e) => {
                          setTypeCC(e.target.value);
                        }}
                        checked={typeof_creditcard === name}
                      />
                    }
                    label={name}
                    sx={{marginRight: "120px"}}
                  />
            ))}
          </RadioGroup>
        </FormControl>

            </Box>

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      views={['month', 'year']}
        label="Expiration Date"
        variant="filled"
        value={dayjs(expiration_date)}
        sx={{ gridColumn: "span 2" }}
        onChange={(e) => {
          setCCexpiration(e);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
                
                     <TextField
                fullWidth
                id='sub'
                type="text"
                label="Security Code"
                name="lastName"
                value={security_code}
                onChange={(e) => setSecurity(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
                   <TextField
                fullWidth
                id='sub'
                type="text"
                value={billing_address}
                onChange={(e) => setBAddress(e.target.value)}
                label="Billing Address"
                name="lastName"
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
                  <TextField
                fullWidth
                id='sub'
                type="text"
                label="City"
                value={credit_city}
                onChange={(e) => setCity(e.target.value)}
                name="lastName"
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
                  <TextField
                fullWidth
                id='sub'
                type="text"
                label="State"
                name="lastName"
                value={credit_state}
                onChange={(e) => setState(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
                  <TextField
                fullWidth
                id='sub'
                type="text"
                label="Zip"
                value={credit_zip}
                onChange={(e) => setZip(e.target.value)}
                name="lastName"
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              
            </Box>
            <br />
            <p> 
            I authorize Kaizen Brain Center to charge the above credit or debit card as payment for services provided.
            </p>
            <br />
            <p>
            This authorization will remain valid until I cancel the authorization. To cancel, I must give a written 30-day notice to Kaizen Brain Center. The account must be in good standing prior to cancellation. I AGREE to notify Kaizen Brain Center when I need to update my credit card information on file.
            </p>
            <br />
            <h4>Signature of patient or responsible party (if applicable)</h4>    
            <br />
              {/* <div className="sign" style={{width:"500px",height:"200px",border:"2px solid black"}} >
              
    <SignatureCanvas
        ref={signRef}
        canvasProps={{
          width: 500,
          height: 200
        }}
      />
    </div>   */}
              {/* <div>
    <Button type="submit" onClick={clearSignatureCanvas} color="secondary" variant="contained"style={{margin:"3vh"}} >
                Clear Signature
              </Button>
    <Button type="submit" onClick={handleGetCanvas} color="secondary" variant="contained" >
                Submit Signature
              </Button>          
  </div>
  {canvasVisibility && 
  <div style={{width:"600px",height:"300px"}}>
  <img src={canvas} alt="signature" />
  </div>
  }  */}
  <TextField
  label = "Signature"
  value = {credit_signature}
  onChange={(e) => setSign(e.target.value)} 
  sx={{ gridColumn: "span 2" }}
  />

<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        format='MM-DD-YYYY'
        variant="filled"
        value={dayjs(credit_date)}
        sx={{ gridColumn: "span 2", marginLeft:"20px" }}
        onChange={(e) => {
          setCrDate(e);
        }}
        renderInput={(params) => <TextField {...params} />}
        
      />
    </LocalizationProvider>
    <br/>
    <br />
    <br />
    {/* <Box> */}
    <Stack sx={{ gridColumn: "span 3" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{width:"220px",  color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>
                {/* </Box> */}
                </Box>


          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>
     </Stack>
     
        </Stack>
      </Container>
    </Page>
    </>
  );
}



export default Agreement_Doctor;
